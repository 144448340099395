import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import useSiteMetadata from '../components/SiteMetadata';

const AboutPageTemplate = ({ title, content, contentComponent }) => {
    const PageContent = contentComponent || Content;

    return (
        <section className="section section--pastel section--with-mask mask-four about-us">
            <div className="container sitteroo-blog-post">
                <div className="content box">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="section">
                                <h1
                                    className="
                                    title
                                    is-size-2
                                    is-size-4-mobile
                                    has-text-weight-bold
                                    is-bold-light
                                "
                                >
                                    {title}
                                </h1>

                                <PageContent
                                    className="content"
                                    content={content}
                                />

                                <p className="monday-picnic has-text-weight-normal is-size-1-mobile has-text-right">
                                    Magda
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

AboutPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
    const { markdownRemark: post } = data;

    const { description } = useSiteMetadata();

    return (
        <Layout>
            <Helmet titleTemplate="%s">
                <title>{`${post.frontmatter.title}`}</title>
                <meta
                    name="description"
                    content={`${post.frontmatter.title} -  ${description}`}
                />
                <meta
                    name="og:description"
                    content={`${post.frontmatter.title} -  ${description}`}
                />
            </Helmet>

            <AboutPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    );
};

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
    query AboutPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
            }
        }
    }
`;
